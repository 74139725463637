/* content.css */

.content-container {
    background-color: #282c34;
    padding: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
    border-radius: 0px;
  }
  
  .contact-info,
  .advertise,
  .discord-embed {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #282c34;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #fff;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  h3 {
    color: #fff;
    margin-top: 15px;
  }
  
  p,
  ul {
    line-height: 1.6;
    margin-bottom: 10px;
    color: #ffffff59;
  }
  
  a {
    color: hsl(212, 50%, 52%);
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #ffffff59;
  }
  
  .discord-embed iframe {
    border: none;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .content-container {
      order: 1; /* Ensure content is at the top */
      margin-bottom: 0; /* Remove bottom margin on mobile */
    }
  
    .sidebar {
      order: 2; /* Ensure sidebar is at the bottom */
      width: 100%;
      height: auto;
      position: fixed; /* Keep the sidebar fixed at the bottom */
      bottom: 0;
      left: 0;
      padding: 10px;
      border-left: none;
      border-top: 1px solid #282c34;
      z-index: 100;
    }
  
    .volume-control {
      display: none; /* Hide the volume slider on mobile */
    }
  }
  