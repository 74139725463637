.App {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  margin: 0;
  position: relative; /* Required for absolute positioning inside .App */
  background-color: #282c34;
}

.player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background: #282c34;
  padding: 10px 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.album-art {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
}

.album-art img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.song-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.song-title {
  font-size: 12px; /* Smaller title font size */
  font-weight: bold;
  color: #fff;
}

.song-artist {
  font-size: 10px;
  color: #fff;
}

.play-pause-container {
  position: absolute; /* Position absolutely to the center of the screen */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the element */
}

.play-pause {
  background: none;
  border: none;
  color: #ff4d4d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px; /* Adjust size as needed */
  width: 60px;  /* Adjust size as needed */
  transition: color 0.3s ease;
}

.play-pause:hover {
  color: #ff1a1a;
}

.volume-control {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.volume-slider {
  width: 100px;
  margin-left: 10px;
  margin-right: 5px;
  cursor: pointer;
}

.volume-icon {
  color: #fff;
}

/* Existing CSS */

/* Media query for smaller screens */
/* Existing CSS */

/* Media query for smaller screens */
/* Media query for smaller screens */
@media (max-width: 600px) {
  .album-art {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px; /* Space between album art and text */
  }

  .song-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px; /* Space between text and play button */
    margin-top: 20px; /* Add more space above the text to push it higher */
  }

  .song-title {
    font-size: 12px; /* Adjust font size */
  }

  .song-artist {
    font-size: 10px; /* Adjust font size */
  }

  .play-pause-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0px; /* Ensure space below text */
  }

  .play-pause {
    height: 50px; /* Adjust button size */
    width: 50px;
  }

  .album-art img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px; /* Space between album art and text */
  }

  .player {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
    width: 100%;
  }

  @media (min-width: 601px) {
    .volume-control {
      display: none;
    }
  }
  
  /* Show volume slider only on mobile */
  @media (max-width: 600px) {
    .volume-control {
      display: flex;
    }
  }

  .volume-slider {
    width: 50%; /* Adjust width as needed */
  }
}