/* header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #282c34;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 101; /* Ensure the header stays above the sidebar */
  }
  
  .header__logo img {
    height: 50px;
    width: auto;
  }